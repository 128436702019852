<template>
  <!-- Pre-program content -->
  <main role="main" class="container">
    <div class="text-center py-md-5 px-md-4 py-3">
      <h1 class="text-uppercase text-center mb-3">This promotion has ended!</h1>
      <p>
        We’re sorry, but receipts must have been submitted by {{ endDate }} to
        qualify for this promotion.
      </p>
    </div>
  </main>
</template>
<script>
export default {
  props: {
    endDate: {
      type: Date,
      required: true,
    },
  },
};
</script>
