<template>
  <the-navigation></the-navigation>

  <router-view></router-view>

  <the-Footer></the-Footer>
</template>

<script>
import TheNavigation from './components/Layout/TheNavigation.vue';
import TheFooter from './components/Layout/TheFooter.vue';
export default {
  components: {
    TheNavigation,
    TheFooter,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
}
</style>
