<template>
  <!-- Pre-program content -->
  <main role="main" class="container">
    <div class="text-center py-md-5 px-md-4 py-3">
      <h1 class="text-uppercase text-center mb-3">
        This promotion hasn’t started yet!
      </h1>
      <p>Please check back on or after {{ startDate }} to participate</p>
      <p>
        In the meantime, you can view the
        <a href="http://snipp.us/!ctjUc" class="anc-link" target="_blank"
          >Terms and Conditions here</a
        >.
      </p>
    </div>
  </main>
</template>
<script>
export default {
  props: {
    startDate: {
      type: Date,
      required: true,
    },
  },
};
</script>
<style scoped>
.navbar-nav {
  display: none;
}
</style>
