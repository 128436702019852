<template>
  <div class="container">
    <div class="row faqs mx-auto mb-5">
      <div class="col-md-12">
        <h1 class="text-uppercase text-center mt-5 mb-3">
          Let’s TroubleShoot That Receipt!
        </h1>
        <p class="text-center mb-4">
          If your receipt image was processed successfully, that receipt is now
          verified! Thank you so much for being a part of this program. <br />If
          we were unable to process the receipt image, there could be different
          reasons why we were unable to do so. Please see the list of reasons
          below to help troubleshoot.
        </p>
        <div class="accordion" id="faqAccordion">
          <div class="card" v-for="(faq, index) in faqs" :key="index">
            <div class="card-header" :id="'heading' + index">
              <h2 class="accordion-header">
                <button
                  :class="{
                    'arrow-open': activeIndex === index,
                    'arrow-closed': activeIndex !== index,
                  }"
                  class="accordion-button"
                  type="button"
                  @click="toggle(index)"
                  :aria-expanded="activeIndex === index ? 'true' : 'false'"
                  :aria-controls="'collapse' + index"
                >
                  {{ faq.question }}
                  <span></span>
                </button>
              </h2>
            </div>
            <div
              :id="'collapse' + index"
              class="accordion-collapse collapse"
              :class="{ show: activeIndex === index }"
              :aria-labelledby="'heading' + index"
              data-bs-parent="#faqAccordion"
            >
              <div class="card-body" v-html="faq.answer"></div>
            </div>
          </div>
        </div>
        <h4 class="text-center mt-5 cust-font px-5 mb-5 fw-400">
          Still need help? Feel free to call us or use our email form (choose
          “promotion-general” under “select topic”).
          <a
            href="https://www.wkkellogg.com/en-us/contact-us.html"
            target="_blank"
            ><u>Reach out now</u></a
          >
        </h4>
      </div>
      <div class="col-md-12 text-center">
        <router-link to="/Home" class="text-uppercase text-center"
          >Return Home</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  data() {
    return {
      faqs: [
        {
          question: 'Blurred or Illegible Image',
          answer:
            ' <p>Lay the receipt flat on a plain, neutral surface with good light.</p><ul><li>Be sure the store name, purchase date, products, prices and total receipt amount are all readable.</li><li>Take a clear photo of the whole receipt (show all 4 corners). For long receipts, you can take up to 5 photos to capture the full receipt.</li></ul>',
        },
        {
          question: 'Multiple Receipts',
          answer:
            '<p>Submit only 1 full receipt at a time.</p><ul><li>For long receipts, you can take up to 5 photos to capture the full receipt, but only one receipt should be included in each submission.</li><li>Lay the receipt flat on a plain surface with good light.</li><li>Be sure the store name, purchase date, products, prices and total receipt amount are all readable.</li></ul>',
        },
        {
          question: 'Duplicate Receipt',
          answer:
            '<p>We’re seeing double! <br/>Looks like this receipt is a duplicate. Good news: you already got the applicable credit for this receipt (or will be within 72 hours of submitting). If you believe we&rsquo;re mistaken that your receipt has already been submitted, please <span style="text-decoration: underline;"><a href="https://www.wkkellogg.com/en-us/contact-us.html" target="_blank">contact us</a></span>.</p>',
        },
        {
          question: 'Incomplete Receipt',
          answer:
            '<p>Ensure the store name, purchase date, products, prices and total receipt amount are all readable.<br />Submit a clear photo of the whole receipt (show all 4 corners). For long receipts, you can submit up to 5 photos to capture the full receipt.</p>',
        },
        {
          question: 'Invalid Receipt',
          answer:
            '<p>Submit an in-store or online receipt that shows the purchase of participating products.</p><ul><li>Lay the receipt flat on a plain surface with good light.</li><li>Make sure it is a clear photo of the whole receipt (showing all 4 corners). For long receipts, you can take up to 5 photos to capture the full receipt.</li><li>Save as the right file type &ndash;.jpg, .jpeg, .tiff, .gif, .png, .bmp, or .pdf.</li></ul><p>Don&rsquo;t exceed 5MB (even if you are sending multiple photos for a long receipt).</p>',
        },
        {
          question: 'Receipt over 30 days Old',
          answer:
            '<p>Darn it! The receipt was sent past the deadline.<br />Receipts must be submitted within 30 days of purchase. We look forward to your next purchase. Be sure to get us the receipt within 30 days!</p>',
        },
        {
          question: ' No Valid Products',
          answer:
            ' <p>Make sure you have purchased at least 1 participating product.</p><ul><li>Check the list of valid products.</li><li>Lay the receipt flat on a plain surface with good light.</li><li>Be sure the store name, purchase date, products, prices and total receipt amount are all readable.</li></ul><p>Submit a clear photo of the whole receipt (show all 4 corners). For long receipts, you can submit up to 5 photos to capture the full receipt.</p>',
        },
        {
          question: 'Multiple Errors',
          answer:
            ' <p>Let&rsquo;s try again! These tips may help avoid errors moving forward:</p><ul><li>Lay the receipt flat on a plain surface with good light.</li><li>Take a clear photo of the whole receipt (show all 4 corners). For long receipts, you can take up to 5 photos to capture the full receipt.</li><li>Be sure the store name, purchase date, products, prices and total receipt amount are all readable.</li></ul><p>Send only 1 full receipt at a time.</p>',
        },
        {
          question: ' Invalid File Size',
          answer:
            '<ul><li>Don&rsquo;t exceed 5MB (even if you are sending multiple photos for a long receipt).</li><li>Make sure it is a clear photo of the whole receipt (showing all 4 corners). For long receipts, you can submit up to 5 photos to capture the full receipt.</li><li>Save as the right file type &ndash;.jpg, .jpeg, .tiff, .gif, .png, .bmp, or .pdf.</li></ul>',
        },
        {
          question: 'Invalid File Type',
          answer:
            ' <ul><li>Save the receipt as the right file type &ndash;jpg, jpeg, tiff, gif, png, bmp, or pdf.</li><li>Don&rsquo;t exceed 5MB (even if you are sending multiple photos for a long receipt).</li><li>Submit a clear photo of the whole receipt (showing all 4 corners). For long receipts, you can submit up to 5 photos to capture the full receipt.</li></ul>',
        },
        {
          question: 'Over 5 Images Submitted',
          answer:
            '<p>5 images is the max for a single receipt. Retake pictures of the entire receipt, using up to 5 images.</p><ul><li>If you need to, fold or omit section(s) of the receipt that don&rsquo;t show valid products.</li><li>Be sure we can still read the store name, purchase date, products, prices and bar code (if applicable)</li></ul>',
        },
      ],
      activeIndex: null,
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style scoped>
#faqAccordion > .card {
  margin-bottom: 15px;
  border-radius: 0px;
  border: 1px solid #404040;
}

#faqAccordion > .card > .card-header {
  padding: 0px;
}

#faqAccordion .accordion-button {
  font-size: 1.1rem;
  color: #000;
  font-weight: 500;
  padding-right: 30px;
  box-shadow: none;
}

#faqAccordion .accordion-button:focus,
#faqAccordion .accordion-button.focus,
#faqAccordion .accordion-button:hover {
  text-decoration: none;
}

.accordion-button:not(.collapsed) {
  background-color: #e9ecef;
}
.accordion-button {
  transition: background-color 0.3s ease;
}
.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  font-family: 'FontAwesome';
  background-image: none;
}
.accordion-button.arrow-open::after {
  content: '\f078';
}

.accordion-button.arrow-closed::after {
  content: '\f077';
}
</style>
