<template>
  <header>
    <nav class="navbar navbar-expand-md navbar-light">
      <div class="container-fluid align-items-sm-center">
        <router-link to="/Home" class="navbar-brand"
          ><img
            src="https://snippcheck.blob.core.windows.net/files/wkkc_kellogg_2007719/brand-logo.png"
            alt="logo"
            class="img-fluid"
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          @click="toggleCollapse"
          aria-controls="navbar-Nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        ></button>
        <div
          :class="{ show: isCollapsed }"
          class="collapse navbar-collapse justify-content-between my-2"
          id="navbar-Nav"
        >
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link to="/Home" class="nav-link text-uppercase"
                >Home</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/Support" class="nav-link text-uppercase"
                >Support</router-link
              >
            </li>
          </ul>
          <!-- <div class="top-social-nav">
            <ul class="list-inline m-0">
              <li>
                <a
                  href="#"
                  class="fb-link social-icon"
                  title="facebook"
                  target="_blank"
                >
                  <i class="fab fa-facebook-square"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="twitter-link social-icon"
                  title="twitter"
                  target="_blank"
                >
                  <i class="fab fa-square-x-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="ig-link social-icon"
                  title="instagram"
                  target="_blank"
                >
                  <i class="fab fa-instagram-square"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="yt-link social-icon"
                  title="youtube"
                  target="_blank"
                >
                  <i class="fab fa-youtube-square"></i>
                </a>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </nav>
  </header>

  <banner></banner>
</template>

<script>
import banner from '../UI/Banner.vue';
export default {
  components: { banner },
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style scoped>
header {
  width: 100%;
}
.navbar {
  padding: 0;
}
#navbar-Nav > ul > li a {
  color: #404040;
  line-height: 1;
  padding: 0.7rem;
  font-size: 1rem;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  width: 12.708333333333332%;
  margin-right: 2rem;
}

#navbar-Nav > ul > li + li {
  margin-left: 10%;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
.social-icon {
  font-size: 2rem;
  border-radius: 5px;
  color: #f26a32;
}
header .navbar {
  padding: 1rem 1.5rem;
  justify-content: space-between;
}
.nav-link {
  background: transparent;
  cursor: pointer;
  color: #000;
  display: inline-block;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.nav-link:hover,
.nav-link:active,
.nav-link.active {
  color: #000;
  border-bottom: 2px solid #f26a32;
}
.toogle-country-list {
  margin: 12px 0 0 20px;
}
.toogle-country-list ul {
  padding: 0;
  margin: 0;
}
.toogle-country-list ul li a {
  font-weight: 700;
  color: #48657c;
  text-transform: uppercase;
  width: auto;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
}
.top-social-nav a:hover {
  color: #176198;
}
@media screen and (max-width: 575px) {
  .navbar-toggler {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #17223c;
    z-index: 9;
    top: 25px;
    right: 20px;
    border: none;
    box-shadow: none;
  }

  .navbar-toggler::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 3px;
    background: #17223c;
    transform: translateY(-10px);
    box-shadow: 0 10px #17223c;
    transition: 0.5s;
  }

  .navbar-toggler::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 3px;
    background: #17223c;
    transform: translateY(10px);
    transition: 0.5s;
  }

  header .navbar {
    padding: 0;
  }

  .navbar-brand {
    display: none;
  }
  .top-social-nav {
    display: flex;
    justify-content: center;
    margin-top: 11px;
  }
  .toogle-country-list {
    margin: 12px 0 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  ul.navbar-nav {
    width: 100% !important;
    display: block !important;
  }
  .navigation {
    margin-left: 0px !important;
  }
  .navigation img {
    max-width: 100%;
  }
  ul.navbar-nav {
    display: inline;
    margin: 0 auto;
    text-align: center;
  }
  ul.navbar-nav li {
    display: block;
    margin: 0 !important;
  }
}
</style>
