<template>
  <!-- import styles if needed -->
  <PageLoader class="mb-5 mt-5" v-if="isLoading"></PageLoader>
  <div v-if="is_loaded" v-show="!isLoading">
    <!-- import styles if needed -->

    <div v-if="is_loaded">
      <div id="current-user-wrapper">
        <snipp-current-user
          :campaign-id="campaignId"
          :user-status="user_status"
          @show-registration="showRegistration"
          @snipp-logout="handleSnippLogout"
          @show-confirmation="showConfirmation"
          @current-user="handleCurrentUser"
        ></snipp-current-user>
      </div>
      <div id="registration-wrapper" v-if="registration_enabled">
        <snipp-registration
          app-source="CampaignWidget"
          refresh-reload="true"
          :campaign-id="campaignId"
          :show-confirmation="show_confirmation"
          :is-logged-out="is_logged_out"
          @is-registered="handleIsRegistered"
        ></snipp-registration>
      </div>
      <div id="thanks-text" v-if="upload_enabled === 'upload_again'">
        <br />
        <h3>Thank you!</h3>
        <p>
          We have received your submission. Please allow 48 hours for
          processing. <br />
          In the meantime, you can view the
          <a href="http://snipp.us/!ctjUc" class="anc-link" target="_blank"
            >Terms and Conditions here</a
          >.
        </p>
        <br />
      </div>
      <div id="upload-wrapper" v-if="upload_enabled">
        <div id="upload-original" v-if="upload_enabled !== 'upload_again'">
          <snipp-upload
            :campaign-id="campaignId"
            include-upload-button="true"
            @snipp-upload-result="handleSnippUploadResult"
          ></snipp-upload>
        </div>
        <div id="upload-again" v-if="upload_enabled === 'upload_again'">
          <snipp-upload
            :campaign-id="campaignId"
            include-upload-again-button="true"
            @snipp-upload-result="handleSnippUploadResult"
          ></snipp-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageLoader from '../../../components/UI/PageLoader.vue';
export default {
  components: { PageLoader },
  props: ['campaignId'],
  emits: [],
  data() {
    return {
      ts: Date.now(),
      isLoading: true,
      is_loaded: false,
      is_logged_out: undefined,
      upload_enabled: undefined,
      registration_enabled: false,
      show_confirmation: undefined,
      user_status: undefined,
    };
  },
  methods: {
    showRegistration(confirmation) {
      if (this.upload_enabled) this.upload_enabled = undefined;
      if (confirmation) {
        this.show_confirmation = true;
      } else {
        this.show_confirmation = undefined;
      }
      this.registration_enabled = true;
    },
    showConfirmation() {
      this.showRegistration(true);
    },
    handleSnippLogout() {
      this.upload_enabled = undefined;
      this.is_logged_out = true;
      this.showRegistration();
    },
    handleIsRegistered() {
      this.user_status = Date.now();
      this.upload_enabled = 'upload';
      this.registration_enabled = false;
    },
    handleSnippUploadResult() {
      this.upload_enabled = 'upload_again';
    },
    handleCurrentUser() {
      this.registration_enabled = false;
      this.upload_enabled = 'upload';
    },
  },
  mounted() {
    this.is_loaded = true;
    setTimeout(() => (this.isLoading = false), 1500);
  },
};
</script>

<style scoped>
.wrapper {
  margin-top: 57px;
}
</style>
