<template>
  <footer class="py-4 brand-bgcolor">
    <div class="">
      <div class="navbar">
        <div class="col-sm-12 p-0 text-center">
          <ul class="list-inline mb-0" id="footer-nav">
            <li>
              <a
                nohref="nohref"
                class="track ot-sdk-show-settings"
                style="cursor: pointer"
                >Cookie Preferences</a
              >
            </li>
            <li class="list-inline-item">
              <router-link
                to="/ReceiptTroubleshooting"
                class="nav-link"
                aria-label="Receipt Troubleshooting"
                alt="Receipt Troubleshooting"
                >Receipt Troubleshooting
              </router-link>
            </li>
            <li class="list-inline-item">
              <a href="http://snipp.us/!ctjUc" target="_blank"
                >Terms &amp; Conditions</a
              >
            </li>
            <li class="list-inline-item">
              <a href="http://snipp.us/!csNwD" target="_blank">Terms of Use</a>
            </li>
            <li class="list-inline-item">
              <a href="http://snipp.us/!csNwP" target="_blank"
                >Privacy Notice</a
              >
            </li>
            <li class="list-inline-item">
              <a href="http://snipp.us/!csNwE" target="_blank">USA Privacy</a>
            </li>
            <li class="list-inline-item">
              <a
                href="https://www.wkkellogg.com/en-us/contact-us.html"
                target="_blank"
                >Contact Us</a
              >
            </li>
            <!-- <li class="list-inline-item">
              <a rel="noopener noreferrer" href="#">Snipp Privacy Policy</a>
            </li> -->
            <li class="list-inline-item">
              <a href="http://snipp.us/!csNwQ" target="_blank"
                >Do Not Sell or share my personal information</a
              >
            </li>
            <li class="list-inline-item">
              <a href="http://snipp.us/!csNwR" target="_blank">Accessibility</a>
            </li>
          </ul>
          <p class="mt-3">
            <small>© {{ new Date().getFullYear() }} WK Kellogg Co. </small>
          </p>
        </div>
        <!-- <div class="col-sm-2 col-12">
          <div class="footer-social-nav">
            <ul class="list-inline m-0">
              <li>
                <a
                  href="#"
                  class="fb-link social-icon"
                  title="facebook"
                  target="_blank"
                >
                  <i class="fab fa-facebook-square"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="twitter-link social-icon"
                  title="twitter"
                  target="_blank"
                >
                  <i class="fab fa-square-x-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="ig-link social-icon"
                  title="instagram"
                  target="_blank"
                >
                  <i class="fab fa-instagram-square"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="yt-link social-icon"
                  title="youtube"
                  target="_blank"
                >
                  <i class="fab fa-youtube-square"></i>
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  color: #fff;
  margin-top: auto;
  /* padding: 10px 20px; */
  padding: 0.5rem 1rem;
}
#footer-nav li + li:before {
  content: ' | ';
  color: #fff;
  margin-right: 7px;
}
.footer-social-nav .social-icon {
  color: #fff;
  font-size: 2rem;
  border-radius: 5px;
}
.footer-social-nav {
  float: right;
}
.footer-social-nav .social-icon:hover {
  color: #176198;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
a {
  font: inherit;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  margin: 5px 0;
  display: inline-block;
  text-decoration: none;
  font-size: 1rem;
}
a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 575px) {
  .navbar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #footer-nav li + li:before {
    content: '';
    margin-right: 0px;
  }
  footer {
    text-align: center;
  }
  footer li.list-inline-item {
    display: block;
  }
  footer li.list-inline-item {
    display: block;
  }
  .footer-social-nav {
    float: none;
  }
}
</style>
